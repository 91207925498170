import React from 'react'
import { Container, Header, Image, Segment } from 'semantic-ui-react'
import Layout from '../../components/layout'
import PageHeader from '../../components/PageHeader'
import { FormattedMessage } from 'gatsby-plugin-intl'

import logo from '../../images/stream_dark_background.svg'

const SolutionStreamPrimary = () => (
  <Segment vertical>
    <Segment basic>
      <Container text textAlign="justified">
        <Header as="h3" size="large" textAlign="center">
          <FormattedMessage id="subtitle-solution-stream" />
        </Header>
        <p>
          <FormattedMessage id="content-solution-stream-1" />
        </p>
      </Container>
    </Segment>
  </Segment>
)

const SolutionStream = () => (
  <Layout>
    <div className="SolutionStreamPage">
      <PageHeader fullWidth>
        <Image src={logo} alt="Stream" centered size="medium" />
      </PageHeader>
      <SolutionStreamPrimary />
    </div>
  </Layout>
)

export default SolutionStream
